// components/benefits/life-insurance/LifeInsuranceEligibility.js
import React from 'react';
import { Container, Typography, Paper, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const LifeInsuranceEligibility = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md">
      <Box sx={{ mb: 4, mt: 2 }}>
        <Button onClick={() => navigate('/benefits/life-insurance')} sx={{ mb: 2 }}>
          Back to Life Insurance
        </Button>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            Life Insurance Eligibility
          </Typography>
          <Typography variant="h6" gutterBottom>
            Eligibility varies by program:
          </Typography>
          <ul>
            <li>SGLI: Most active duty service members are automatically eligible</li>
            <li>VGLI: Must apply within 1 year and 120 days from discharge</li>
            <li>Family SGLI: Available to spouses and dependent children of service members with full-time SGLI</li>
            <li>Service-Disabled Veterans Insurance: Must have a service-connected disability</li>
          </ul>
          <Box sx={{ mt: 3 }}>
            <Button 
              variant="contained"
              onClick={() => navigate('/benefits/life-insurance/application')}
            >
              Start Application
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};
