import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppBar, Toolbar, Button, Typography, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { logout } from './redux/authSlice';

// Existing Components
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Dashboard from './components/Dashboard';
import DocumentManagement from './components/DocumentManagement';
import MilitaryBenefits from './components/MilitaryBenefits';
import Notifications from './components/Notifications';
import ProfileSettings from './components/ProfileSettings';

// VA Loan Components
import {
  VALoanOverview,
  VALoanEligibility,
  VALoanApplication
} from './components/benefits/va-loan';

// GI Bill Components
import {
  GIBillOverview,
  GIBillEligibility,
  GIBillApplication
} from './components/benefits/gi-bill';

// Life Insurance Components
import {
  LifeInsuranceOverview,
  LifeInsuranceEligibility,
  LifeInsuranceApplication
} from './components/benefits/life-insurance';

function App() {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Router>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Compass
            </Typography>
            {isAuthenticated ? (
              <>
                <Button color="inherit" component={Link} to="/" startIcon={<DashboardIcon />}>
                  Dashboard
                </Button>
                <Button color="inherit" component={Link} to="/documents" startIcon={<DescriptionIcon />}>
                  Documents
                </Button>
                <Button color="inherit" component={Link} to="/benefits" startIcon={<AccountBalanceIcon />}>
                  Benefits
                </Button>
                <Button color="inherit" component={Link} to="/notifications" startIcon={<NotificationsIcon />}>
                  Notifications
                </Button>
                <Button color="inherit" component={Link} to="/profile" startIcon={<PersonIcon />}>
                  Profile
                </Button>
                <Button color="inherit" onClick={handleLogout} startIcon={<ExitToAppIcon />}>
                  Logout
                </Button>
              </>
            ) : (
              <>
                <Button color="inherit" component={Link} to="/login">Login</Button>
                <Button color="inherit" component={Link} to="/register">Register</Button>
              </>
            )}
          </Toolbar>
        </AppBar>

        <Routes>
          {/* Auth Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          
          {/* Main Routes */}
          <Route 
            path="/" 
            element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/documents" 
            element={isAuthenticated ? <DocumentManagement /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/benefits" 
            element={isAuthenticated ? <MilitaryBenefits /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/notifications" 
            element={isAuthenticated ? <Notifications /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/profile" 
            element={isAuthenticated ? <ProfileSettings /> : <Navigate to="/login" />} 
          />

          {/* VA Loan Routes */}
          <Route 
            path="/benefits/va-loan" 
            element={isAuthenticated ? <VALoanOverview /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/benefits/va-loan/eligibility" 
            element={isAuthenticated ? <VALoanEligibility /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/benefits/va-loan/application" 
            element={isAuthenticated ? <VALoanApplication /> : <Navigate to="/login" />} 
          />

          {/* GI Bill Routes */}
          <Route 
            path="/benefits/gi-bill" 
            element={isAuthenticated ? <GIBillOverview /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/benefits/gi-bill/eligibility" 
            element={isAuthenticated ? <GIBillEligibility /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/benefits/gi-bill/application" 
            element={isAuthenticated ? <GIBillApplication /> : <Navigate to="/login" />} 
          />

          {/* Life Insurance Routes */}
          <Route 
            path="/benefits/life-insurance" 
            element={isAuthenticated ? <LifeInsuranceOverview /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/benefits/life-insurance/eligibility" 
            element={isAuthenticated ? <LifeInsuranceEligibility /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/benefits/life-insurance/application" 
            element={isAuthenticated ? <LifeInsuranceApplication /> : <Navigate to="/login" />} 
          />
        </Routes>
      </Box>
    </Router>
  );
}

export default App;