import React from 'react';
import { Container, Typography, Paper, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const GIBillOverview = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md">
      <Box sx={{ mb: 4, mt: 2 }}>
        <Button onClick={() => navigate('/benefits')} sx={{ mb: 2 }}>
          Back to Benefits
        </Button>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            GI Bill Benefits Overview
          </Typography>
          <Typography variant="body1" paragraph>
            The Post-9/11 GI Bill helps you pay for school or job training. If you've served on active duty after September 10, 2001, you may qualify for the Post-9/11 GI Bill.
          </Typography>
          <Typography variant="h6" gutterBottom>
            What's Covered:
          </Typography>
          <ul>
            <li>College tuition and fees</li>
            <li>Monthly housing allowance</li>
            <li>Books and supplies stipend</li>
            <li>Technical training and certifications</li>
            <li>Flight training</li>
          </ul>
          <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
            <Button 
              variant="contained" 
              onClick={() => navigate('/benefits/gi-bill/eligibility')}
            >
              Check Eligibility
            </Button>
            <Button 
              variant="outlined"
              onClick={() => navigate('/benefits/gi-bill/application')}
            >
              Apply Now
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};