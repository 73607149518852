import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const MilitaryBenefits = () => {
  const navigate = useNavigate();

  const benefits = [
    {
      id: 'va-loan',
      title: 'VA Home Loan',
      description: 'Helps service members, veterans, and eligible surviving spouses become homeowners',
      path: '/benefits/va-loan'
    },
    {
      id: 'gi-bill',
      title: 'GI Bill',
      description: 'Helps cover the costs for education and training',
      path: '/benefits/gi-bill'
    },
    {
      id: 'life-insurance',
      title: 'Life Insurance',
      description: 'Offers life insurance options for veterans and service members',
      path: '/benefits/life-insurance'
    }
  ];

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
        Military Benefits
      </Typography>
      <Grid container spacing={3}>
        {benefits.map((benefit) => (
          <Grid item xs={12} sm={6} md={4} key={benefit.id}>
            <Card 
              elevation={3}
              sx={{
                height: '100%',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              <CardActionArea 
                onClick={() => navigate(benefit.path)}
                sx={{ height: '100%' }}
              >
                <CardContent>
                  <Typography variant="h5" component="h2" gutterBottom>
                    {benefit.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {benefit.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default MilitaryBenefits;