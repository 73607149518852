// src/redux/benefitsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBenefits } from '../services/api';

export const fetchBenefits = createAsyncThunk(
  'benefits/fetchBenefits',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getBenefits();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const benefitsSlice = createSlice({
  name: 'benefits',
  initialState: {
    benefits: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBenefits.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBenefits.fulfilled, (state, action) => {
        state.benefits = action.payload;
        state.loading = false;
      })
      .addCase(fetchBenefits.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default benefitsSlice.reducer;