import React from 'react';
import { Container, Typography, Paper, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const VALoanEligibility = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md">
      <Box sx={{ mb: 4, mt: 2 }}>
        <Button onClick={() => navigate('/benefits/va-loan')} sx={{ mb: 2 }}>
          Back to VA Loan
        </Button>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            VA Loan Eligibility
          </Typography>
          <Typography variant="h6" gutterBottom>
            Basic Eligibility Requirements:
          </Typography>
          <ul>
            <li>Served 90 consecutive days of active service during wartime</li>
            <li>Served 181 days of active service during peacetime</li>
            <li>Served 6 years in the National Guard or Reserves</li>
            <li>Are the spouse of a service member who died in the line of duty</li>
          </ul>
          <Box sx={{ mt: 3 }}>
            <Button 
              variant="contained"
              onClick={() => navigate('/benefits/va-loan/application')}
            >
              Start Application
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};