import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDocumentsAPI, uploadDocumentAPI, fetchDocumentSecureUrlAPI, deleteDocumentAPI } from '../components/Helpers/api';

// Fetch all documents
export const fetchDocuments = createAsyncThunk(
  'documents/fetchDocuments',
  async (_, { rejectWithValue }) => {
    try {
      const documents = await fetchDocumentsAPI();
      return documents;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred while fetching documents');
    }
  }
);

// Upload a document
export const uploadDocument = createAsyncThunk(
  'documents/uploadDocument',
  async (formData, { rejectWithValue }) => {
    try {
      const newDocument = await uploadDocumentAPI(formData);
      return newDocument;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred while uploading the document');
    }
  }
);

// Fetch secure URL for a document
export const fetchDocumentSecureUrl = createAsyncThunk(
  'documents/fetchDocumentSecureUrl',
  async (documentId, { rejectWithValue }) => {
    try {
      const response = await fetchDocumentSecureUrlAPI(documentId);
      
      // Assuming the backend response has the filename (or path) of the document
      const fileName = response.data.fileName;  // Adjust this according to the backend response structure

      // Construct the full URL to access the file
      const secureUrl = `https://api.legacymaps.org/tempfiles/${fileName}`;

      return { documentId, secureUrl };
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred while fetching the document URL');
    }
  }
);

// Delete a document by ID
export const deleteDocument = createAsyncThunk(
  'documents/deleteDocument',
  async (documentId, { rejectWithValue }) => {
    try {
      await deleteDocumentAPI(documentId);
      return documentId;  // Return documentId to remove from the state
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred while deleting the document');
    }
  }
);

const documentsSlice = createSlice({
  name: 'documents',
  initialState: {
    documents: [],
    loading: false,
    error: null,
    secureUrls: {},  // Store secure URLs by document ID
  },
  reducers: {},
  extraReducers: (builder) => {
    // Fetch Documents
    builder
      .addCase(fetchDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.documents = action.payload;
        state.loading = false;
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Upload Document
    builder
      .addCase(uploadDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        state.documents.push(action.payload);  // Add the uploaded document to the list
        state.loading = false;
      })
      .addCase(uploadDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Fetch Document Secure URL
    builder
      .addCase(fetchDocumentSecureUrl.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDocumentSecureUrl.fulfilled, (state, action) => {
        const { documentId, secureUrl } = action.payload;
        state.secureUrls[documentId] = secureUrl;  // Save the URL using document ID as the key
        state.loading = false;
      })
      .addCase(fetchDocumentSecureUrl.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Delete Document
    builder
      .addCase(deleteDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        // Remove the document by its ID
        state.documents = state.documents.filter(doc => doc._id !== action.payload);
        state.loading = false;
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default documentsSlice.reducer;
