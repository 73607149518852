// /Helpers/api.js - Axios instance with common configuration and API functions

import axios from 'axios';

// Create an axios instance with default configurations
const api = axios.create({
  baseURL: 'https://api.legacymaps.org', // Set the base URL for the API
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Ensures cookies (JWT) are included with requests
});

// Optional: Set up interceptors for global error handling or other purposes
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // You can handle global error responses here if needed
    console.error('API Error:', error.response ? error.response.data : error.message);
    return Promise.reject(error); // Pass the error to be handled in individual calls
  }
);

// Function to fetch documents for the logged-in user
export const fetchDocumentsAPI = async () => {
  try {
    const response = await api.get('/documents'); // Endpoint for fetching all documents
    return response.data; // Return documents data from API
  } catch (error) {
    throw error; // Handle error locally or globally in calling function
  }
};

// Function to upload a document
export const uploadDocumentAPI = async (formData) => {
  try {
    const response = await api.post('/documents/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Proper headers for file upload
      },
    });
    return response.data; // Return response data (uploaded document info)
  } catch (error) {
    throw error; // Handle error locally or globally in calling function
  }
};

// Function to fetch secure URL for a document
export const fetchDocumentSecureUrlAPI = async (documentId) => {
  try {
    const response = await api.get(`/documents/download/${documentId}`); // Adjusted path for secure URL
    return response.data.secureUrl; // Assuming the backend returns { secureUrl }
  } catch (error) {
    throw error; // Handle error locally or globally in calling function
  }
};

// Function to delete a document
export const deleteDocumentAPI = async (documentId) => {
  try {
    const response = await api.delete(`/documents/${documentId}`); // Adjusted path for deleting a document
    return response.data; // Assuming the backend returns success message
  } catch (error) {
    throw error; // Handle error locally or globally in calling function
  }
};

export default api;
