import React from 'react';
import { Container, Typography, List, ListItem, ListItemText, Paper } from '@mui/material';

const Notifications = () => {
  const notifications = [
    { id: 1, message: 'Document expiring in 30 days', date: '2024-10-22' },
    { id: 2, message: 'New benefit available', date: '2024-10-21' },
    { id: 3, message: 'Profile update required', date: '2024-10-20' },
  ];

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Notifications
      </Typography>
      <Paper elevation={3} sx={{ p: 2 }}>
        <List>
          {notifications.map((notification) => (
            <ListItem key={notification.id}>
              <ListItemText 
                primary={notification.message} 
                secondary={`Date: ${notification.date}`} 
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Container>
  );
};

export default Notifications;