// components/benefits/va-loan/VALoanOverview.js
import React from 'react';
import { Container, Typography, Paper, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const VALoanOverview = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md">
      <Box sx={{ mb: 4, mt: 2 }}>
        <Button onClick={() => navigate('/benefits')} sx={{ mb: 2 }}>
          Back to Benefits
        </Button>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            VA Home Loan Overview
          </Typography>
          <Typography variant="body1" paragraph>
            VA home loans are provided by private lenders and guaranteed by the VA, offering:
          </Typography>
          <ul>
            <li>No down payment required in most cases</li>
            <li>Competitive interest rates</li>
            <li>Limited closing costs</li>
            <li>No private mortgage insurance required</li>
          </ul>
          <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
            <Button 
              variant="contained" 
              onClick={() => navigate('/benefits/va-loan/eligibility')}
            >
              Check Eligibility
            </Button>
            <Button 
              variant="outlined"
              onClick={() => navigate('/benefits/va-loan/application')}
            >
              Apply Now
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};