// components/benefits/gi-bill/GIBillApplication.js
import React from 'react';
import { 
  Container, 
  Typography, 
  Paper, 
  Button, 
  Box, 
  TextField, 
  Grid,
  MenuItem 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const GIBillApplication = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md">
      <Box sx={{ mb: 4, mt: 2 }}>
        <Button onClick={() => navigate('/benefits/gi-bill')} sx={{ mb: 2 }}>
          Back to GI Bill
        </Button>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            GI Bill Application
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                label="First Name" 
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                label="Last Name" 
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                fullWidth 
                label="Service Number" 
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Type of Training"
                variant="outlined"
              >
                <MenuItem value="college">College Degree</MenuItem>
                <MenuItem value="vocational">Vocational Training</MenuItem>
                <MenuItem value="certification">Certification Program</MenuItem>
                <MenuItem value="flight">Flight Training</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField 
                fullWidth 
                label="School or Training Facility" 
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary">
                Submit Application
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
};