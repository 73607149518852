// components/benefits/life-insurance/LifeInsuranceApplication.js
import React from 'react';
import { 
  Container, 
  Typography, 
  Paper, 
  Button, 
  Box, 
  TextField, 
  Grid,
  MenuItem 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const LifeInsuranceApplication = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md">
      <Box sx={{ mb: 4, mt: 2 }}>
        <Button onClick={() => navigate('/benefits/life-insurance')} sx={{ mb: 2 }}>
          Back to Life Insurance
        </Button>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            Life Insurance Application
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                label="First Name" 
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                label="Last Name" 
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Insurance Type"
                variant="outlined"
              >
                <MenuItem value="sgli">Servicemembers' Group Life Insurance (SGLI)</MenuItem>
                <MenuItem value="vgli">Veterans' Group Life Insurance (VGLI)</MenuItem>
                <MenuItem value="fsgli">Family SGLI</MenuItem>
                <MenuItem value="sdvi">Service-Disabled Veterans Insurance</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField 
                select
                fullWidth 
                label="Coverage Amount" 
                variant="outlined"
              >
                <MenuItem value="100000">$100,000</MenuItem>
                <MenuItem value="250000">$250,000</MenuItem>
                <MenuItem value="400000">$400,000</MenuItem>
                <MenuItem value="500000">$500,000</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField 
                fullWidth 
                label="Email" 
                type="email"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary">
                Submit Application
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
};