// components/benefits/life-insurance/LifeInsuranceOverview.js
import React from 'react';
import { Container, Typography, Paper, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const LifeInsuranceOverview = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md">
      <Box sx={{ mb: 4, mt: 2 }}>
        <Button onClick={() => navigate('/benefits')} sx={{ mb: 2 }}>
          Back to Benefits
        </Button>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            Life Insurance Benefits Overview
          </Typography>
          <Typography variant="body1" paragraph>
            VA life insurance provides financial security for Veterans, service members, and their families.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Available Programs:
          </Typography>
          <ul>
            <li>Servicemembers' Group Life Insurance (SGLI)</li>
            <li>Veterans' Group Life Insurance (VGLI)</li>
            <li>Family SGLI</li>
            <li>Traumatic Injury Protection</li>
            <li>Service-Disabled Veterans Insurance</li>
          </ul>
          <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
            <Button 
              variant="contained" 
              onClick={() => navigate('/benefits/life-insurance/eligibility')}
            >
              Check Eligibility
            </Button>
            <Button 
              variant="outlined"
              onClick={() => navigate('/benefits/life-insurance/application')}
            >
              Apply Now
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};