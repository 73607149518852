import React from 'react';
import { Container, Typography, Paper, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const GIBillEligibility = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md">
      <Box sx={{ mb: 4, mt: 2 }}>
        <Button onClick={() => navigate('/benefits/gi-bill')} sx={{ mb: 2 }}>
          Back to GI Bill
        </Button>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            GI Bill Eligibility Requirements
          </Typography>
          <Typography variant="body1" paragraph>
            To be eligible for the Post-9/11 GI Bill, you must meet at least one of these requirements:
          </Typography>
          <ul>
            <li>Served at least 90 days on active duty (either all at once or with breaks in service) on or after September 11, 2001</li>
            <li>Received a Purple Heart on or after September 11, 2001, and were honorably discharged</li>
            <li>Served for at least 30 continuous days on or after September 11, 2001, and were honorably discharged with a service-connected disability</li>
            <li>Are a dependent child using benefits transferred by a qualifying Veteran or service member</li>
          </ul>
          <Box sx={{ mt: 3 }}>
            <Button 
              variant="contained"
              onClick={() => navigate('/benefits/gi-bill/application')}
            >
              Start Application
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};