// components/benefits/va-loan/VALoanApplication.js
import React from 'react';
import { 
  Container, 
  Typography, 
  Paper, 
  Button, 
  Box, 
  TextField, 
  Grid 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const VALoanApplication = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md">
      <Box sx={{ mb: 4, mt: 2 }}>
        <Button onClick={() => navigate('/benefits/va-loan')} sx={{ mb: 2 }}>
          Back to VA Loan
        </Button>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            VA Loan Application
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                label="First Name" 
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                label="Last Name" 
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                fullWidth 
                label="Service Number" 
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                fullWidth 
                label="Email" 
                type="email" 
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary">
                Submit Application
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
};